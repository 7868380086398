


















.cls-1
    fill none
    stroke #292828
    stroke-miterlimit 10
    stroke-width 4.29px
.cls-2
    fill #292828
